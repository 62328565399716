import React, {useState} from 'react';
import { Configuration, OpenAIApi } from "openai";
import key from "./key.js"
import './App.css';

function App() {
  const [kind, setKind] = useState("");
  const [place, setPlace] = useState("");
  const [result, setResult] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const configuration = new Configuration({
    apiKey: key,
  });
  const openai = new OpenAIApi(configuration);

  const generateImage = async (prompt) => {
    const res = await openai.createImage({
      prompt: prompt,
      n: 1,
      size: "512x512",
    });
    return (res.data.data[0].url);
  };

  const generateName = async (kind) => {
    const res = await openai.createCompletion({
      model: "text-ada-001",
      prompt: "Please find a hero animal name which is a " + kind,
      temperature: 1,
      max_tokens: 2000,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
    });
    return res.data.choices[0].text;
  }

  const generateResult =  async () => {
    setLoading(true);
    setResult("");
    setName("");
    const name = await generateName(kind);
    const res = await generateImage(kind + " superhero named " + name + " in " + place);
    setResult(res);
    setName(name);
    setLoading(false);
  };

  return (
    <div className="app">
      <header>
        <h1>AnimaHero</h1>
        <p>Want images of cat heroes? Or dog ones? We got you! This site is dedicated to making random animal hero names and images.</p>
      </header>
      <main className="main">
        <input
          type="text"
          className="app-input"
          placeholder="Enter an animal kind..."
          onChange={(e) => setKind(e.target.value)}
        />
        <input
          type="text"
          className="app-input"
          placeholder="Enter an place..."
          onChange={(e) => setPlace(e.target.value)}
        />
        <button onClick={() => generateResult()}>Generate an Image</button>
        {loading ? (<p>Loading. Please wait...</p>) : (<React.Fragment></React.Fragment>)}
        {name.length > 0 ? (
          <h3>{name}</h3>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        {result.length > 0 ? (
          <img className="result-image" src={result} alt="result" />
        ) : (
          <React.Fragment></React.Fragment>
        )}

      </main>
      <footer>
        <p>Powered by <a href="https://openai.com/api/">OpenAI API</a></p>
      </footer>
    </div>
  );
}

export default App;
